import React from 'react';
import PropTypes from 'prop-types';
import { connect } from '../../../common/components/runtime-context';
import LinkList from '../../../link-list';
import LinkText from '../../../common/components/link-text';
import {
  getShowPostCount,
  getUseMobileDesingSettings,
  getUseMobileLayoutSettings,
} from '../../selectors/archive-selectors';
import { getArchiveWidgetData } from '../../aggregated-archive/selectors';

const Archive = ({ archiveData, showPostCount, useMobileDesignSettings, useMobileLayoutSettings }) => {
  return (
    <nav aria-label={archiveData.ariaLabel}>
      <LinkList
        links={archiveData.archive.map((archiveLink) => {
          const monthAndYear = archiveLink.display.text;
          const a11yText = archiveLink.display.a11yText;
          const postCount = showPostCount ? archiveLink.display.postCount : null;

          return {
            key: archiveLink.key,
            path: archiveLink.path,
            text: <LinkText text={monthAndYear} postCount={postCount} a11yText={a11yText} />,
          };
        })}
        emptyState={{ text: archiveData.emptyState }}
        useMobileDesignSettings={useMobileDesignSettings}
        useMobileLayoutSettings={useMobileLayoutSettings}
      />
    </nav>
  );
};

Archive.propTypes = {
  archiveData: PropTypes.object.isRequired,
  showPostCount: PropTypes.bool.isRequired,
  useMobileDesignSettings: PropTypes.bool.isRequired,
  useMobileLayoutSettings: PropTypes.bool.isRequired,
};

const mapRuntimeToProps = (state) => ({
  archiveData: getArchiveWidgetData(state),
  showPostCount: getShowPostCount(state),
  useMobileDesignSettings: getUseMobileDesingSettings(state),
  useMobileLayoutSettings: getUseMobileLayoutSettings(state),
});

export default connect(mapRuntimeToProps)(Archive);
